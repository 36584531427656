import { useMemo } from 'react';

import {
    useService as useBaseService,
    Resource,
    ResourceCreateParams,
} from './Base';
import { Service as ResourceService } from './DeletableResource';
import { ListParams, ListResponse, createURLParams } from './util';

export const ExpiryDuration = {
    DAYS_30: 30,
    DAYS_60: 60,
    DAYS_90: 90,
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ExpiryDuration = typeof ExpiryDuration[keyof typeof ExpiryDuration];

export interface Tracker extends Resource {
    redirectURLTemplate: string;

    urlExpireAfterDays: ExpiryDuration;

    visitCount: number;
    uniqueVisitCount: number;
}

export interface TrackerVisit extends Resource {
    live: boolean;
    tracker: string;
    orderID: string;
    device: string;
    ipAddress: string;
}

type CreateParams = Omit<
    Tracker,
    'visitCount' | 'uniqueVisitCount' | 'deleted' | keyof Resource
> &
    ResourceCreateParams;

export interface UpdateParams {
    description?: string;
    redirectURLTemplate?: string;
    urlExpireAfterDays?: ExpiryDuration;
}

export class Service extends ResourceService<Tracker> {
    async create(data: CreateParams): Promise<Tracker> {
        return await this.base.fetchAPI<Tracker>(`/${this.route}`, {
            method: 'POST',
            body: data,
        });
    }

    async update(id: string, params: UpdateParams) {
        return await this.base.fetchAPI<Tracker>(`/${this.route}/${id}`, {
            method: 'POST',
            body: params,
        });
    }

    async getVisits(
        id: string,
        params: ListParams
    ): Promise<ListResponse<TrackerVisit>> {
        const urlParams = createURLParams(params);
        return await this.base.fetchAPI(
            `/${this.route}/${id}/visits?${urlParams.toString()}`
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'trackers'), [base]);
};
