import React, {
    type PropsWithoutRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { type LocalFile, type ParseStepResult, parse } from 'papaparse';

import { useNotificationContext } from '../context/Notification';
import {
    type MailingListImport,
    FileType,
    ProcessStatus as MailingListImportProcessStatus,
    useService as useMailingListImportService,
} from '../services/MailingListImports';
import {
    type MailingList,
    ProcessStatus as MailingListProcessStatus,
    useService as useMailingListService,
} from '../services/MailingLists';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';

import FileUpload from './FileUpload';
import RowErrors from './RowErrors';

export interface Row {
    Description?: string;
    'First Name'?: string;
    'Last Name'?: string;
    Email?: string;
    'Phone Number'?: string;
    'Company Name'?: string;
    'Job Title'?: string;
    Address?: string;
    'Address 2'?: string;
    City?: string;
    'Province or State'?: string;
    'Postal or Zip'?: string;
    'Country Code'?: string;
}

export const ADDRESS_MAPPING = {
    description: 'Description',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    companyName: 'Company Name',
    jobTitle: 'Job Title',
    addressLine1: 'Address',
    addressLine2: 'Address 2',
    provinceOrState: 'Province or State',
    postalOrZip: 'Postal or Zip',
    countryCode: 'Country Code',
} as const;

const ADDITIONAL_ADDRESS_MAPPING = {
    amount: 'Amount',
    memo: 'Memo',
    chequeNumber: 'Cheque Number',
} as const;

const normalizeKey = (key: string) => key.toLowerCase().replace(/\s+/g, '');

const isValidRow = (r: Row) => {
    // Find keys for which there are valid non-empty values
    const normKeys = Object.entries(r)
        .filter(([_, v]) => typeof v === 'string' && v.trim().length !== 0)
        .map(([k, _]) => normalizeKey(k));

    return (
        (normKeys.includes('firstname') || normKeys.includes('companyname')) &&
        normKeys.includes('address') &&
        normKeys.includes('countrycode')
    );
};

const validateHeader = (
    header: string,
    uniqueValue: string,
    uniqueHeaderIndex: number,
    headerIndex: number
) => {
    if (header.toLocaleLowerCase().includes(uniqueValue)) {
        // A value of 0 means we have not seen the value before
        // as the values are initialized to 0
        if (uniqueHeaderIndex === 0) {
            // Return the index + 1 because the header could be at
            // position 0 and then our checks would not work for checking
            // if the index is 0 or negative.
            return headerIndex + 1;
        } else {
            return -1;
        }
    }
    return uniqueHeaderIndex;
};

const updateHeaders = (
    headers: string[],
    value: string,
    first: number,
    second?: number
) => {
    // Subtract 1 from the index when we check the position because we
    // add 1 to the index when we validate the header
    if (second !== undefined) {
        if (first > 0 && second === 0) {
            headers[first - 1] = value;
        }
        if (second > 0 && first === 0) {
            headers[second - 1] = value;
        }

        return;
    }

    if (first > 0) {
        headers[first - 1] = value;
    }
};

export const parseHeaders = (headers: string[]) => {
    // Store a number to check the index of the header.
    //
    // Store the index of the header + 1 in the number if it is the first time
    // we have seen the unique header (value is 0).
    // Add +1 to the index to be able to check the case of the first time we
    // are seeing the unique value ('name' set to 0 initially) and that
    // value is the first in the header list (index 0).
    // When we insert the value into the list, subtract 1 from the index value
    // to account for that.
    // If we have seen the header before (value is greater than 0), then we
    // set the value to a negative value to indicate that this value is not
    // unique.
    let nameIndex = 0;
    let provinceIndex = 0;
    let stateIndex = 0;
    let countryIndex = 0;
    let zipIndex = 0;
    let postalIndex = 0;

    for (const [i, header] of headers.entries()) {
        nameIndex = validateHeader(header, 'name', nameIndex, i);
        provinceIndex = validateHeader(header, 'province', provinceIndex, i);
        stateIndex = validateHeader(header, 'state', stateIndex, i);
        countryIndex = validateHeader(header, 'country', countryIndex, i);
        zipIndex = validateHeader(header, 'zip', zipIndex, i);
        postalIndex = validateHeader(header, 'postal', postalIndex, i);
    }

    updateHeaders(headers, 'Company Name', nameIndex);
    updateHeaders(headers, 'Province or State', provinceIndex, stateIndex);
    updateHeaders(headers, 'Country Code', countryIndex);
    updateHeaders(headers, 'Postal or Zip', postalIndex, zipIndex);

    return headers;
};

interface CSVData {
    validRowCount: number;
    invalidRowCount: number;
    headers: string[];
}

export const parseCSV = (file: File): Promise<CSVData> => {
    return new Promise((resolve) => {
        let headers: string[] = [];
        let invalidRowCount = 0;
        let validRowCount = 0;
        let first = true;

        parse(file as LocalFile, {
            step: (row: ParseStepResult<string[]>) => {
                // First row will be the headers
                if (first) {
                    first = false;
                    headers = parseHeaders(row.data);
                    return;
                }

                // Create a `row` object from the headers
                const _row: Record<string, string> = {};
                for (const [i, key] of headers.entries()) {
                    _row[key] = row.data[i];
                }

                if (isValidRow(_row)) {
                    ++validRowCount;
                } else {
                    ++invalidRowCount;
                }
            },
            complete: () => {
                resolve({
                    validRowCount,
                    invalidRowCount,
                    headers,
                });
            },
        });
    });
};

export interface CompletedUploadData {
    mailingList: MailingList;
    mailingListImport: MailingListImport;
}

type UploadContactsDialogProps = PropsWithoutRef<{
    open: boolean;
    onClose: (e: {}) => void;
    onCompleted: (d: CompletedUploadData) => void;
    sampleURL?: string;
}>;

const UploadContactsDialog = ({
    onClose,
    open,
    sampleURL,
    onCompleted,
}: UploadContactsDialogProps) => {
    const { dispatchError } = useNotificationContext();
    const listImportService = useMailingListImportService();
    const listService = useMailingListService();

    const [file, setFile] = useState<File | null>(null);
    const [creatingContacts, setCreatingContacts] = useState(false);
    const [processedCount, setProcessedCount] = useState(0);
    const [csvData, setCSVData] = useState<CSVData>({
        invalidRowCount: 0,
        validRowCount: 0,
        headers: [],
    });

    const rowCount = useMemo(
        () => csvData.validRowCount + csvData.invalidRowCount,
        [csvData]
    );

    useEffect(() => {
        if (file) {
            parseCSV(file).then(setCSVData);
        }
    }, [file]);

    const processRows = useCallback(async () => {
        // TODO: Display error message
        if (!file || !csvData.validRowCount) {
            return;
        }

        setCreatingContacts(true);

        // TODO?: Technically, the invalid rows will _not_ be skipped as we
        // are just passing through the file. We _could_ create a new file
        // in memory and pass that through.
        let mailingListImport = await listImportService.create({
            file,
            fileType: FileType.CSV,
            receiverAddressMapping: ADDRESS_MAPPING,
            receiverMergeVariableMapping: Object.fromEntries(
                csvData.headers
                    .filter(
                        (header) => header in Object.values(ADDRESS_MAPPING)
                    )
                    .map((header) => {
                        for (const [key, value] of Object.entries(
                            ADDITIONAL_ADDRESS_MAPPING
                        )) {
                            if (value === header) {
                                return [key, value];
                            }
                        }

                        return [header, header];
                    })
            ),
        });

        try {
            for (;;) {
                mailingListImport = await listImportService.get(
                    mailingListImport.id
                );

                if (
                    mailingListImport.status ===
                    MailingListImportProcessStatus.COMPLETED
                ) {
                    break;
                }

                if (
                    mailingListImport.status ===
                    MailingListImportProcessStatus.CHANGES_REQUIRED
                ) {
                    throw new Error();
                }

                await new Promise((res) => setTimeout(res, 250));
            }
        } catch (e) {
            dispatchError(
                mailingListImport.errors.map((err) => err.message).join('\n')
            );
            setFile(null);
            setProcessedCount(0);
            setCreatingContacts(false);
            return;
        }

        const mailingList = await listService.create({
            metadata: { postgrid_dashboard: '' },
        });
        await listService.createJob(mailingList.id, {
            addMailingListImports: [mailingListImport.id],
        });

        try {
            for (;;) {
                const processingList = await listService.get(mailingList.id);

                if (
                    processingList.status === MailingListProcessStatus.COMPLETED
                ) {
                    break;
                }

                await new Promise((res) => setTimeout(res, 250));
            }
        } catch (e) {
            dispatchError(
                'An error occured in uploading contacts. Please refresh and try again.'
            );
            setFile(null);
            setProcessedCount(0);
            setCreatingContacts(false);
            return;
        }

        setCreatingContacts(false);
        onCompleted({
            mailingListImport,
            mailingList,
        });
        // Clear the dialog state
        setFile(null);
        setProcessedCount(0);
    }, [
        csvData,
        file,
        listImportService,
        dispatchError,
        listService,
        onCompleted,
    ]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-testid="upload-contacts-dialog"
        >
            <DialogContent>
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        Upload a CSV File
                    </Typography>
                </DialogTitle>
                <DialogContentText>
                    <Typography component="span">
                        You can create a large number of contacts at once by
                        uploading a CSV file. Download a sample CSV file{' '}
                        <Link
                            href={
                                sampleURL ||
                                'https://pg-prod-bucket-1.s3.amazonaws.com/assets/sample_recipients.csv'
                            }
                        >
                            here
                        </Link>
                        . Note that you can add your own columns and those will
                        be supplied as variable data to your orders.
                    </Typography>
                </DialogContentText>
                <FileUpload
                    label="Upload CSV File"
                    accept="text/csv"
                    file={file}
                    setFile={setFile}
                    disabled={creatingContacts}
                />
                {file && (
                    <RowErrors
                        validRowCount={csvData.validRowCount}
                        invalidRowCount={csvData.invalidRowCount}
                    />
                )}
                {creatingContacts && (
                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={(processedCount / rowCount || 1) * 100}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            size="large"
                            fullWidth
                            disabled={creatingContacts}
                            data-testid="cancel-upload-contacts-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async (e) => {
                                await processRows();
                                onClose(e);
                            }}
                            size="large"
                            fullWidth
                            disabled={creatingContacts || rowCount <= 0}
                            data-testid="upload-contacts-button"
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default UploadContactsDialog;
