import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
//
import { EmbeddedRoutes } from '../routes';
import { CreateOrderOriginProvider } from '../context/CreateOrderOrigin';
import EditTemplate from '../pages/EditTemplate';
import InvalidEmbeddedRoute from '../pages/InvalidEmbeddedRoute';

declare global {
    interface Window {
        Intercom: any;
    }
}

const hideChat = () => {
    if (window.Intercom) {
        window.Intercom('update', {
            hide_default_launcher: true,
        });
    }
};

const useInitialQuery = () => {
    const { search } = useLocation();

    // We only want this to run initially
    // As we navigate to different pages, the query will update
    // and the initial query the user passed will not be present.
    // eslint-disable-next-line
    return useMemo(() => new URLSearchParams(search), []);
};

// Returns the configuration for the current embed session
const useEmbedConfig = (query: URLSearchParams) => {
    // Configs
    useEffect(() => {
        const hideChatQuery = query.get('hideChat') === 'true';
        if (hideChatQuery) {
            hideChat();
        }
    }, [query]);
};

const Embed = () => {
    const query = useInitialQuery();
    useEmbedConfig(query);

    return (
        <Switch>
            <Route
                path={EmbeddedRoutes.TEMPLATE_EDITOR_SESSIONS}
                component={() => (
                    <CreateOrderOriginProvider>
                        <EditTemplate fullScreen />
                    </CreateOrderOriginProvider>
                )}
            />
            <Route
                path={EmbeddedRoutes.FALLBACK}
                component={InvalidEmbeddedRoute}
            />
        </Switch>
    );
};

export default Embed;
